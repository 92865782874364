import { CollectionsDto } from '@/Model/collections/types';
import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import CollectionCardComponent from '@/commoncomponents/CollectionCardComponent/CollectionCardComponent.vue';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ConfigureLearningLibraryCollection from '@/components/learningLibrary/ConfigureLearningLibraryCollection.vue';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import { AlgoliaIndex } from '@/services/cms/algoliaService';
import { getCollection, updateCollection } from '@/services/collections/api';
import {
  AlgoliaHitObject,
  ContentCardData,
  algoliaToCardData
} from '@/utilities/cmsUtilities';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { AxiosError } from 'axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import accountListStore from '@/store/modules/accountsList';
import { getCourseCardStatus } from '@/utilities/cms/courseStatus';
import { ToastType } from '@/Model/toastType';
import { ToastPosition } from '@/Model/toastPosition';
import draggable from 'vuedraggable';

@Component({
  components: {
    CmsCardComponent,
    CollectionCardComponent,
    ConfigureLearningLibraryCollection,
    OffCanvas,
    BouncingPreloaderComponent,
    draggable
  }
})
export default class SingleCollectionPage extends Vue {
  @Prop({ type: Number, required: true }) collectionId!: number;
  private screenText = new ScreenText();

  public isLoadingAccountCourseStatuses = false;

  public collection: CollectionsDto | null = null;
  public items: ContentCardData[] = [];
  public modifiedCollection: CollectionsDto | null = null;

  public isLoadingCollection = false;
  public getCollectionErrorMessage: null | string = null;

  public isUpdatingCollection = false;
  public updateCollectionErrorMessage: null | string = null;

  public isDeletingCollectionPost = false;
  public deleteCollectionPostErrorMessage: null | string = null;

  public showConfigureCollectionFlyout = false;

  @Watch('collectionId', { immediate: true })
  collectionIdWatcher(): void {
    this.isLoadingAccountCourseStatuses = true;
    accountListStore.setAccountCourseStatuses().finally(() => {
      this.isLoadingAccountCourseStatuses = false;
    });
  }

  @Watch('cmsCourseStatus', { deep: true })
  cmsCourseStatusWatcher(): void {
    this.fetchCollectionById(this.collectionId);
  }

  get canEdit(): boolean {
    if (this.collection == null) {
      return false;
    }

    return !this.collection.isLocked;
  }

  get isLoading(): boolean {
    return this.isLoadingAccountCourseStatuses || this.isLoadingCollection;
  }

  get collectionName(): string {
    return this.collection
      ? this.collection.name
      : '';
  }

  get itemsCount(): number {
    return this.items.length;
  }

  get myCollectionsPageRouteTo(): Location {
    return {
      name: APP_CONST.APP_ROUTES.MY_COLLECTIONS.name
    };
  }

  get learningLibraryRouteTo(): Location {
    return {
      name: APP_CONST.APP_ROUTES.CMS_CATALOG.name
    };
  }

  get emptyCollectionTitle(): string {
    return this.screenText.getScreenText('EMPTY_COLLECTION_TITLE');
  }

  get emptyCollectionCopy(): string {
    return this.screenText.getScreenText('EMPTY_COLLECTION_COPY');
  }

  get emptyCollectionCallToAction(): string {
    return this.screenText.getScreenText('EMPTY_COLLECTION_CTA');
  }

  get cmsCourseStatus() {
    return accountListStore.cmsCourseStatus;
  }

  public async fetchCollectionById(id: number): Promise<void> {
    try {
      this.isLoadingCollection = true;
      this.getCollectionErrorMessage = null;

      const { data } = await getCollection({ id });

      this.collection = data;

      const { results } = await AlgoliaIndex.getObjects<AlgoliaHitObject>(
        data.posts
      );

      const contentItems: AlgoliaHitObject[] = [];

      for (const result of results) {
        if (!result) {
          continue;
        }
        contentItems.push(result);
      }

      const cards = algoliaToCardData(contentItems);
      const statusCards = getCourseCardStatus(
        cards,
        accountListStore.cmsCourseStatus
      );
      this.items = statusCards;
    }
    catch (error) {
      this.getCollectionErrorMessage = (error as AxiosError | Error).message;
    }
    finally {
      this.isLoadingCollection = false;
    }
  }

  public async onOrderChange(event: any): Promise<void> {
    if (!event.moved || !this.items || !this.collection) {
      return;
    }
    this.collection.posts = this.items.map(item => item.id) as string[];
    this.modifiedCollection = { ...this.collection };
    await this.saveCollection();
  }

  public editCollection(): void {
    if (!this.collection) {
      return;
    }
    this.modifiedCollection = { ...this.collection };
    this.showConfigureCollectionFlyout = true;
  }

  public cancelCollectionConfiguration(): void {
    this.showConfigureCollectionFlyout = false;
    this.modifiedCollection = null;
  }

  public async saveCollection(): Promise<void> {
    if (!this.modifiedCollection) {
      return;
    }

    try {
      this.isUpdatingCollection = true;
      this.updateCollectionErrorMessage = null;

      await updateCollection({
        id: this.modifiedCollection.id,
        name: this.modifiedCollection.name,
        userId: APP_UTILITIES.getUserID(),
        posts: this.modifiedCollection.posts
      });

      this.collection = { ...this.modifiedCollection };
      this.showConfigureCollectionFlyout = false;
      this.modifiedCollection = null;
    }
    catch (error) {
      this.updateCollectionErrorMessage = (error as AxiosError | Error).message;
    }
    finally {
      this.isUpdatingCollection = false;
    }
  }

  public async deleteContentItem({ id }: ContentCardData): Promise<void> {
    if (!this.collection || !id) {
      return;
    }

    this.modifiedCollection = {
      ...this.collection,
      posts: this.collection.posts.filter(postId => postId !== id)
    };

    try {
      this.isDeletingCollectionPost = true;
      this.deleteCollectionPostErrorMessage = null;

      await updateCollection({
        id: this.modifiedCollection.id,
        name: this.modifiedCollection.name,
        userId: APP_UTILITIES.getUserID(),
        posts: this.modifiedCollection.posts
      });

      this.collection = { ...this.modifiedCollection };
      this.items = this.items.filter(item => item.id !== id);
      this.modifiedCollection = null;

      APP_UTILITIES.showToastMessage(
        '1 removed',
        ToastType.Success,
        ToastPosition.BottomCenter
      );
    }
    catch (error) {
      this.deleteCollectionPostErrorMessage = (error as
        | AxiosError
        | Error).message;

      APP_UTILITIES.showToastMessage(
        'Removing content failed',
        ToastType.Error,
        ToastPosition.BottomCenter
      );
    }
    finally {
      this.isDeletingCollectionPost = false;
    }
  }
}
