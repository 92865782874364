import { render, staticRenderFns } from "./SingleCollectionPage.vue?vue&type=template&id=2907d828&scoped=true&"
import script from "./SingleCollectionPage.vue?vue&type=script&lang=ts&"
export * from "./SingleCollectionPage.vue?vue&type=script&lang=ts&"
import style0 from "./SingleCollectionPage.less?vue&type=style&index=0&id=2907d828&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2907d828",
  null
  
)

export default component.exports